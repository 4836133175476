import axios from 'axios'
import { ref } from 'vue'

const getMovie = () => {
  const movie = ref({})
  const loading = ref(false)
  const scanning = ref(false)
  const loadMovie = async id => {
    loading.value = true
    axios.get(`https://plex.nzbhorizon.com/library/metadata/${id}?includeExtras=1&includeExternalMedia=1&X-Plex-Token=${process.env.VUE_APP_X_Plex_Token}`)
      .then(res => {
        res.data.MediaContainer.Metadata[0].X_Plex_Token = process.env.VUE_APP_X_Plex_Token
        movie.value = res.data.MediaContainer.Metadata[0]
        loading.value = false
      })
      .catch(err => {
        loading.value = false
      })
  }

  const scanMovieDirectory = (path) => {
    scanning.value = true
    axios.get(`https://plex.nzbhorizon.com/library/sections/1/refresh?path=${path}&X-Plex-Token=${process.env.VUE_APP_X_Plex_Token}`)
      .then(res => {
        scanning.value = false
      })
      .catch(err => {
        loading.value = false
      })
  }
  return { movie, loading, loadMovie, scanning, scanMovieDirectory }
}

export default getMovie
